// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-datenschutz-index-mdx": () => import("./../../../src/pages/datenschutz/index.mdx" /* webpackChunkName: "component---src-pages-datenschutz-index-mdx" */),
  "component---src-pages-impressum-index-mdx": () => import("./../../../src/pages/impressum/index.mdx" /* webpackChunkName: "component---src-pages-impressum-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-karriere-admin-mw-index-mdx": () => import("./../../../src/pages/karriere/admin-mw/index.mdx" /* webpackChunkName: "component---src-pages-karriere-admin-mw-index-mdx" */),
  "component---src-pages-karriere-index-mdx": () => import("./../../../src/pages/karriere/index.mdx" /* webpackChunkName: "component---src-pages-karriere-index-mdx" */),
  "component---src-pages-kontakt-index-mdx": () => import("./../../../src/pages/kontakt/index.mdx" /* webpackChunkName: "component---src-pages-kontakt-index-mdx" */),
  "component---src-pages-portfolio-index-mdx": () => import("./../../../src/pages/portfolio/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-index-mdx" */),
  "component---src-pages-portfolio-it-beratung-index-mdx": () => import("./../../../src/pages/portfolio/it-beratung/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-it-beratung-index-mdx" */),
  "component---src-pages-portfolio-it-dienstleistung-index-mdx": () => import("./../../../src/pages/portfolio/it-dienstleistung/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-it-dienstleistung-index-mdx" */),
  "component---src-pages-portfolio-softwareentwicklung-index-mdx": () => import("./../../../src/pages/portfolio/softwareentwicklung/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-softwareentwicklung-index-mdx" */),
  "component---src-pages-portfolio-systemintegration-big-data-index-mdx": () => import("./../../../src/pages/portfolio/systemintegration/big-data/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-systemintegration-big-data-index-mdx" */),
  "component---src-pages-portfolio-systemintegration-datenbanken-index-mdx": () => import("./../../../src/pages/portfolio/systemintegration/datenbanken/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-systemintegration-datenbanken-index-mdx" */),
  "component---src-pages-portfolio-systemintegration-ecm-index-mdx": () => import("./../../../src/pages/portfolio/systemintegration/ecm/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-systemintegration-ecm-index-mdx" */),
  "component---src-pages-portfolio-systemintegration-index-mdx": () => import("./../../../src/pages/portfolio/systemintegration/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-systemintegration-index-mdx" */),
  "component---src-pages-portfolio-systemintegration-maschinen-index-mdx": () => import("./../../../src/pages/portfolio/systemintegration/maschinen/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-systemintegration-maschinen-index-mdx" */),
  "component---src-pages-portfolio-systemintegration-produktionssysteme-index-mdx": () => import("./../../../src/pages/portfolio/systemintegration/produktionssysteme/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-systemintegration-produktionssysteme-index-mdx" */),
  "component---src-pages-portfolio-systemintegration-schnittstellen-index-mdx": () => import("./../../../src/pages/portfolio/systemintegration/schnittstellen/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-systemintegration-schnittstellen-index-mdx" */),
  "component---src-pages-referenzen-index-mdx": () => import("./../../../src/pages/referenzen/index.mdx" /* webpackChunkName: "component---src-pages-referenzen-index-mdx" */),
  "component---src-pages-startseite-index-mdx": () => import("./../../../src/pages/startseite/index.mdx" /* webpackChunkName: "component---src-pages-startseite-index-mdx" */),
  "component---src-pages-unternehmen-geschaeftsfuehrung-index-mdx": () => import("./../../../src/pages/unternehmen/geschaeftsfuehrung/index.mdx" /* webpackChunkName: "component---src-pages-unternehmen-geschaeftsfuehrung-index-mdx" */),
  "component---src-pages-unternehmen-geschichte-profil-index-mdx": () => import("./../../../src/pages/unternehmen/geschichte-profil/index.mdx" /* webpackChunkName: "component---src-pages-unternehmen-geschichte-profil-index-mdx" */),
  "component---src-pages-unternehmen-index-mdx": () => import("./../../../src/pages/unternehmen/index.mdx" /* webpackChunkName: "component---src-pages-unternehmen-index-mdx" */),
  "component---src-pages-unternehmen-partner-index-mdx": () => import("./../../../src/pages/unternehmen/partner/index.mdx" /* webpackChunkName: "component---src-pages-unternehmen-partner-index-mdx" */),
  "component---src-pages-unternehmen-team-index-mdx": () => import("./../../../src/pages/unternehmen/team/index.mdx" /* webpackChunkName: "component---src-pages-unternehmen-team-index-mdx" */),
  "component---src-pages-unternehmen-technologien-index-mdx": () => import("./../../../src/pages/unternehmen/technologien/index.mdx" /* webpackChunkName: "component---src-pages-unternehmen-technologien-index-mdx" */)
}

